import React from 'react';
import {useLocation} from 'react-router-dom';
import BoxContent from '../../components/BoxContent';
import LogoImageSection from '../../components/BoxContent/LogoImageSection';
import {Button} from '@edume/magnificent';
import AppConfig from '../../app-config';
import StorageService from '../../services/storageService';

import './style.scss';

type LocationProps = {
  state: {
    accessToken: boolean;
    courseLinkModuleId: string;
  };
};

/**
 * This component allows users with both admin and learner
 * roles to select between the LFE and DS platforms.
 */

const SelectPlatform = () => {
  const location: LocationProps = useLocation();
  const {accessToken, courseLinkModuleId} = location.state;

  const handleAdminClick = () => {
    window.location.assign(
      `${AppConfig.dsBaseUrl}/msTeams?token=${accessToken}`
    );
  };

  const handleLearnerClick = () => {
    let lfeUrl = `${AppConfig.lfeBaseUrl}/msTeams?token=${accessToken}`;
    if (courseLinkModuleId) {
      lfeUrl = `${lfeUrl}&moduleId=${courseLinkModuleId}`;
    }
    console.log(`LFE course link URL`, lfeUrl); // logging on purpose.
    StorageService.clear(AppConfig.storageKeys.moduleId);
    window.location.assign(lfeUrl);
  };
  return (
    <div className={'page-container'}>
      <BoxContent
        minWidth={`400px`}
        maxWidth={`480px`}
        className={`select-platform-content`}
      >
        <LogoImageSection />
        <div className={'body'}>
          <h2 className={'primary-text text-centre title'}>Access as:</h2>
          <Button fullWidth={true} type='primary' onClick={handleAdminClick}>
            <p className={'button-text'}>Admin</p>
          </Button>
          <Button fullWidth={true} type='primary' onClick={handleLearnerClick}>
            <p className={'button-text'}>Learner</p>
          </Button>
          <p className={'note'}>
            <strong>Please note:</strong> When you leave your session, you'll be
            asked to choose again. This helps us ensure you have the right
            access for the next part of your journey.
          </p>
        </div>
      </BoxContent>
    </div>
  );
};

export default SelectPlatform;
