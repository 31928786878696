import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useErrorHandler} from 'react-error-boundary';
import {ApiError} from '../types';
import AuthService from '../services/authService';
import AppConfig from '../app-config';
import {useMsalAuth} from './shared/useMsalAuth';
import StorageService from '../services/storageService';
import PermissionService from '../services/permissionService';
import routes from '../routes';
import {sendClickedSignupEvent} from '../tracking/trackingFunctions';

export const useLogin = () => {
  const history = useHistory();
  const {getAccessTokenSilently, login} = useMsalAuth();
  const handleError = useErrorHandler();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleSignUpButtonClick = async () => {
    try {
      setIsLoading(true);
      const accessToken = await login();
      if (!accessToken) {
        // user dismissed login popup
        return;
      } else {
        sendClickedSignupEvent(accessToken);
        const signUpTrialResult = await AuthService.signUpTrialAccount(
          accessToken
        );
        if (
          signUpTrialResult instanceof ApiError &&
          signUpTrialResult.statusCode === 401
        ) {
          history.push(routes.completeSetup);
        } else {
          const isGlobalAdmin =
            await PermissionService.checkIfUserIsGlobalAdmin(accessToken);

          if (isGlobalAdmin) {
            history.push(routes.grantPermissions);
          } else {
            window.location.assign(
              `${AppConfig.dsBaseUrl}/msTeams?token=${accessToken}&isTrial=true`
            );
          }
        }
      }
      setIsLoading(false);
    } catch (e: any) {
      console.error(e);
      return null;
    }
  };

  const handleLoginButtonClick = async () => {
    setIsLoading(true);
    const accessToken = await login();
    if (!accessToken) {
      setIsLoading(false);
      // user closes popup without logging in
      return;
    } else {
      await checkUserTypeAndRedirect(accessToken);
    }
    setIsLoading(false);
  };

  const checkUserTypeAndRedirect = async (
    accessToken: string
  ): Promise<boolean> => {
    const isGlobalAdmin = await PermissionService.checkIfUserIsGlobalAdmin(
      accessToken
    );
    const userTypeResult = await AuthService.checkUserType(accessToken);
    const arePermissionsGranted =
      await PermissionService.checkIfPermissionsGrantedForOrganisation(
        accessToken
      );
    if (userTypeResult instanceof ApiError) {
      handleError(Error(`Unable to find your account details in our system.`));
      return false;
    } else if (isGlobalAdmin && !arePermissionsGranted) {
      history.push(routes.grantPermissions);
      return true;
    } else {
      const showAdminPanel =
        userTypeResult.isAdmin && !userTypeResult.isLearner;
      const showSelectPlatformOption =
        userTypeResult.isAdmin && userTypeResult.isLearner;
      const courseLinkModuleId = getCourseLinkModuleId();
      if (showSelectPlatformOption) {
        history.push({
          pathname: routes.selectPlatform,
          state: {accessToken, courseLinkModuleId},
        });
      } else if (showAdminPanel) {
        if (courseLinkModuleId) {
          // admin is unauthorised to take the course.
          history.push(routes.unauthorizedCourseAccess);
        } else {
          window.location.assign(
            `${AppConfig.dsBaseUrl}/msTeams?token=${accessToken}`
          );
        }
      } else {
        let lfeUrl = `${AppConfig.lfeBaseUrl}/msTeams?token=${accessToken}`;
        if (courseLinkModuleId) {
          lfeUrl = `${lfeUrl}&moduleId=${courseLinkModuleId}`;
        }
        console.log(`LFE course link URL`, lfeUrl); // logging on purpose.
        StorageService.clear(AppConfig.storageKeys.moduleId);
        window.location.assign(lfeUrl);
      }
      return true;
    }
  };

  const checkIfUserIsLoggedIn = async (arePermissionsGranted = false) => {
    setIsLoading(true);
    let accessToken = await getAccessTokenSilently(arePermissionsGranted);

    if (accessToken) {
      await checkUserTypeAndRedirect(accessToken);
    }
    setIsLoading(false);
  };

  const getCourseLinkModuleId = (): number | null =>
    StorageService.retrieveData<number>(AppConfig.storageKeys.moduleId);

  return {
    handleLoginButtonClick,
    handleSignUpButtonClick,
    isLoading,
    checkIfUserIsLoggedIn,
  };
};
