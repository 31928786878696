import axios from 'axios';
import AppConfig from '../app-config';
import {ApiError, UserTypeResponse} from '../types';
import HttpService from './httpService';

class AuthService {
  public static checkUserType = async (
    accessToken
  ): Promise<UserTypeResponse | ApiError> => {
    const result = await HttpService.get<UserTypeResponse>(
      AppConfig.apiEndpoints.user.checkType,
      {
        Authorization: accessToken,
      }
    );
    if (result instanceof ApiError) {
      return result;
    }

    return result.data;
  };

  public static signUpTrialAccount = async (
    accessToken
  ): Promise<boolean | ApiError> => {
    const result = await HttpService.post<boolean>(
      AppConfig.apiEndpoints.trial.signUp,
      {
        token: accessToken,
      }
    );
    if (result instanceof ApiError) {
      return result;
    }

    return result.data;
  };

  public static makeExternalRequest = async (
    method,
    url,
    authorization
  ): Promise<object | ApiError> => {
    const options = {
      headers: {
        authorization,
      },
    };
    const {data} = await axios.request({
      method,
      url,
      ...options,
    });
    return data;
  };

  public static getUserProfile = async (
    accessToken
  ): Promise<object | ApiError> => {
    const result = await this.makeExternalRequest(
      'GET',
      `${AppConfig.graphBaseUrl}/${AppConfig.graphUserEndpoint}`,
      `Bearer ${accessToken}`
    );
    return result || {};
  };
}

export default AuthService;
