const routes = {
  tab: `/tab`, // TODO: this will be removed in the future
  tabConfig: `/config`,
  login: `/login`,
  privacy: `/privacy`,
  terms: `/termsofuse`,
  contactCsTeam: `/contact-cs-team`,
  unauthorizedCourseAccess: `/unauthorized-course-access`,
  signUp: `/signup`,
  grantPermissions: `/grant-permissions`,
  completeSetup: `/complete-setup`,
  selectPlatform: `/select-platform`,
};

export default routes;
